import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    questionData : [],
    teamId : '',
    personId : '',
    isLoading : false,
    messageType : '',
    messageCondition : false,
    message : ''
}

export const surveyCompletedValidatorServer = createAsyncThunk(
    'survey/surveyCompletedValidatorServer',
    async(params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/CheckSubmissionDNAJSON?Token='+params.usertoken+'&PersonID='+params.personId+'&TeamID='+params.teamId+'&CategoryID='+(params.categoryId == '' ? '%' : params.categoryId)+'&SectionID='+(params.sectionId == '' ? '%' : params.sectionId)+'&SeqID='+(params.seqId == '' ? '%' : params.seqId)+'&Score='+(params.score == '' ? '%' : params.score), {
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

export const surveyQuestionnaireServer = createAsyncThunk(
    'survey/surveyQuestionServer',
    async (params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/QuestionnaireJSON?Token='+params.usertoken+'&QuestionType=DNA',{
                method : 'GET'
            })
            const data = await response.json()
            return data
        }
        catch(err){
            return err
        }
    }
)

export const submitQuestionnaireServer = createAsyncThunk(
    'survey/submitQuestionnaireServer',
    async(params) => {
        try{
            const response = await fetch('https://services.clarity4d.com/c4dwebservice/ServiceC4D.asmx/SubmitAnswerDNAJSON?Token='+params.usertoken+'&TeamID='+params.teamId+'&PersonID='+params.personId+'&CategoryID='+params.categoryId+'&SectionID='+params.sectionId+'&SeqID='+params.seqId+'&Score='+params.score,{
                method : 'GET'
            })
            const data = await response.json();
            return data
        }
        catch(err){
            return err
        }
    }
)

const surveyQuestionSlice = createSlice({
    name : 'surveyQuestion',
    initialState,
    reducers: {
        setTeamId : (state, action) => {
            state.teamId = action.payload
            console.log("setTeamId",action.payload)
        },
        setPersonId : (state, action) => {
            state.personId = action.payload
        },
        setSuccessMessage : (state, action) => {
            state.message = 'Completed successfully'
            state.messageCondition = true
            state.messageType = 'success'
        },
        setErrorMessage : (state, action) => {
            state.message = 'Survey incomplete'
            state.messageCondition = true
            state.messageType = 'error'
        },
        setMessageToFalse : (state, action) => {
            state.messageCondition = false
        }
    },
    extraReducers : {
        [surveyQuestionnaireServer.pending] : (state, action) => {

        },
        [surveyQuestionnaireServer.fulfilled] : (state, action) => {
            state.questionData = action.payload.Questionnaire.Question
        },
        [surveyQuestionnaireServer.rejected] : (state, action) => {

        },
        [submitQuestionnaireServer.pending] : (state, action) => {
                state.message = ''
                state.messageCondition = false
                state.messageType = ''
        },
        [submitQuestionnaireServer.fulfilled] : (state, action) => {
            console.log("submitQuestionnaireServer",action.payload.Error)
            if(Object.keys(action.payload) == 'Error'){
                state.message = action.payload.Error
                state.messageCondition = true
                state.messageType = 'error'
            }
        },
        [submitQuestionnaireServer.rejected] : (state, action) => {

        },
        [surveyCompletedValidatorServer.pending] : (state, action) => {
                state.message = ''
                state.messageCondition = false
                state.messageType = ''
                state.isLoading = true
        },
        [surveyCompletedValidatorServer.fulfilled] : (state, action) => {
            console.log("action.payload for validator server", action.payload.Result)
            if((action.payload.Result).toString() == 'Survey Already Submitted'){
                // window.alert(action.payload.Result)
                state.message = action.payload.Result
                state.messageCondition = true
                state.messageType = 'error'
            }
            state.isLoading = false
        },
        [surveyCompletedValidatorServer.rejected] : (state, action) => {

        }
    }
})

export const {setAnswerBoolState, setTeamId, setPersonId, setSuccessMessage, setErrorMessage, setMessageToFalse} = surveyQuestionSlice.actions
export default surveyQuestionSlice.reducer