import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DraftsIcon from '@mui/icons-material/Drafts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SearchIcon from '@mui/icons-material/Search';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SendIcon from '@mui/icons-material/Send';
import '../common-navbar/navbar.css';
import Tooltip from '@mui/material/Tooltip';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const drawerWidth = 240;



const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',


    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      background: 'black',
      color: 'white',
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      background: 'black',
      color: 'white',
    }),
  }),
);

export default function Navbar(props) {
  const theme = useTheme();
  const xlResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xl'));
  const lgResolutionIdentifier = useMediaQuery(theme.breakpoints.down('lg'));
  const mdResolutionIdentifier = useMediaQuery(theme.breakpoints.down('md'));
  const smResolutionIdentifier = useMediaQuery(theme.breakpoints.down('sm'));
  const xsResolutionIdentifier = useMediaQuery(theme.breakpoints.down('xs'));
  const [largeRes, setLargeRes] = React.useState(false)
  const [smallRes, setSmallRes] = React.useState(false)
  React.useEffect(() => {
    if (xlResolutionIdentifier || lgResolutionIdentifier) {
      setLargeRes(true)
      setSmallRes(false)
    }
    else {
      setLargeRes(false)
      setSmallRes(true)
    }
  }, [xlResolutionIdentifier, lgResolutionIdentifier, mdResolutionIdentifier, smResolutionIdentifier, xsResolutionIdentifier])

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    props.drawerClose()
  };

  return (
    <>


      {largeRes && <SwipeableDrawer
        anchor={'left'}
        open={props.open}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        <DrawerHeader>
          <IconButton onClick={props.drawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* {['Dashboard', 'Account', 'Sent Mail', 'Chart View','Settings'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 {index === 0 && <DashboardIcon />}
                 
                
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))} */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateOne}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Dashboard" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon />
              </ListItemIcon></Tooltip>
              <ListItemText primary={"Dashboard"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateTwo}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Team Management" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ManageAccountsIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Team Management"} s sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateTeamMembers}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Team Members" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GroupAddIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Team Members"} s sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateThree}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Survey & Report" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SendIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Survey & Report"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={props.navigateFour}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Status" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BarChartIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Status"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigationFive}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Settings" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Settings"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </SwipeableDrawer>}
      {smallRes && <Drawer variant="permanent" open={props.open} className="sidebar_bg">
        <DrawerHeader>
          <IconButton onClick={props.drawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {/* {['Dashboard', 'Account', 'Sent Mail', 'Chart View','Settings'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: props.open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: props.open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 {index === 0 && <DashboardIcon />}
                 
                
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: props.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))} */}
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateOne}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Dashboard" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <DashboardIcon />
              </ListItemIcon></Tooltip>
              <ListItemText primary={"Dashboard"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateTwo}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Team Management" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ManageAccountsIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Team Management"} s sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateTeamMembers}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Team Members" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GroupAddIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Team Members"} s sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigateThree}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Survey & Report" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SendIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Survey & Report"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={props.navigateFour}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Status" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BarChartIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Status"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={props.navigationFive}
              sx={{
                minHeight: 48,
                justifyContent: props.open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <Tooltip title="Settings" placement="right" arrow><ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary={"Settings"} sx={{ opacity: props.open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>}
    </>
  );
}
