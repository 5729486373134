import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import BreadCrumb from '../../components/common-breadcrumb-section/BreadCrumb';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import jsPDF from 'jspdf'
import ReportDialog from './../../components/report-dialog/ReportDialog'
import Button from '@mui/material/Button';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import IconContentCard from "../../components/common-icon-content-card/IconContentCard";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import GroupRemoveOutlinedIcon from '@mui/icons-material/GroupRemoveOutlined';
import AddOutlined from '@mui/icons-material/AddOutlined';
import IconBtn from '../../components/common-icon-btn/IconBtn';
import { DataGrid } from '@mui/x-data-grid';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CommonDataGrid from '../../components/common-data-grid/CommonDataGrid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import GroupsIcon from '@mui/icons-material/Groups';
import CommonSelect from '../../components/common-select/CommonSelect';
import BarChartIcon from '@mui/icons-material/BarChart';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../IssueSurveyContainer/issuesurvey.css';
import BtnContent from '../../components/common-content-btn/BtnContent';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSelector, useDispatch } from 'react-redux';
import { teamsMemServer, teamsMemreportLink } from '../../features/teams/teamsSlice';
import Divider from '@mui/material/Divider';
import { setTeamMemId, } from '../../features/teams/teamsSlice';
import { listAllTemplatesServer, } from '../../features/settings/SettingsSlice';

import { addRecipient, removeRecipient, recordSurveyMail, setMailSubject, setMailBody, sendMail, setSenderInformationEmpty, publishService, teamReportExists, PublishTeamReport, TeamReportDelete, setMailPdfLink, setBccMail} from '../../features/issueSurvey/issueSurveySlice';
import emailjs from 'emailjs-com';
import SnackBar from '../../components/snackBar-layout-wrapper/snackBar-layout-wrapper';
import BackDropLoader from "../../components/common-backdrop-loader/BackdropLoader";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useNavigate } from "react-router";
import ConfirmDelete from '../../components/confirm-delete-dialog/ConfirmDelete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChipInput from 'material-ui-chip-input'

const IssueSurvey = () => {
    var showPublishBtn = 0
    const { teamsList } = useSelector((store) => store.dashboard)
    const { teamMemList, isLoadingTeamMem, team_id, messageCondition, message, messageType, teamsQueLink } = useSelector((store) => store.teams)
    console.log('taketeamId',team_id)
    {
        teamMemList.map((x) => {
            if (x.dateSubmitted != '') {
                ++showPublishBtn;
            }
        })
    }
    const dispatch = useDispatch()
    const [attachment, setAttachment] = useState(false)
    const [cnfDelete, setCnfDelete] = useState(false)
    const { usertoken } = useSelector((store) => store.login)
    const { recipient, senderInformation, mailSubject, mailBody, isMessageLoading, messageIssueCondition, issueType, messageIssue, snackmessage, type, snackmessageCondition, pdfReport, publishTableShow, pdfLink, mailPdfLink, surveyLink } = useSelector((store) => store.issueSurvey)
    const { criteria, allTemplate } = useSelector((store) => store.settings)
    console.log('PDFLINK', pdfLink.substring(pdfLink.indexOf('s/') + 1));
    const pdfName = pdfLink != '' ? pdfLink.slice(pdfLink.indexOf('profiles/') + 'profiles/'.length) : ''
    const [isOpenTeams, setIsOpenTeams] = useState("");
    const [isOpenTeammanagement, setIsOpenTeammanagement] = useState("");
    console.log('checkrecipientLength',recipient)
    const [isOpenTeamToggle, setIsOpenTeamToggle] = useState(recipient.length>0?true:false);
    const [isOpenInfoIcon, setIsOpenInfoIcon] = useState(recipient.length>0?false:true);
    const [reportOpen, setReportOpen] = useState(false);
    const [publishLoader, setPublishLoader] = useState(false)
    const [checkBoxTracker, setCheckBoxTracker] = useState([])
    const [sendNonMail, setSendNonMail] = useState(false)
    const [unknowMailArray, setUnknownMailArray] = useState([])
    const [bcc, setBcc] = useState([])
    const location = useLocation();

    // const [abcArr, setAbcArr] = useState([])
    const navigate = useNavigate()
    const handleOpenTeams = (value) => {
        setIsOpenTeams(true);
        setIsOpenTeammanagement(false);
    }
 
    useEffect(() => {
        if (pdfReport.hasOwnProperty('Report')) {
            // setReportOpen(true)
            navigate('/pdf', { replace: false })
        }
    }, [pdfReport])
    useEffect(()=>{
        console.log('checkuseEffectRecipient',recipient)
      if(recipient.length>0){
        setIsOpenTeamToggle(true)
        setIsOpenInfoIcon(false)
      }
      else{
        setIsOpenTeamToggle(false)
        setIsOpenInfoIcon(true)
      }
    },[recipient])
    useEffect(() => {
        dispatch(listAllTemplatesServer({ usertoken, criteria })).then((res) => {
            console.log("fetched successfully", res)
        }).catch((err) => {
            console.log("error in list all template", err)
        })
    }, [criteria])


    function createData(name, calories, fat, carbs) {
        return { name, calories, fat, carbs };
    }

    const rowsone = [
        createData('Published Reports', "checkbox", "PDFName", 24),

    ];


    function reportDelete() {
        dispatch(TeamReportDelete({ usertoken, team_id })).then((res) => {
            if (res.payload.Result == 'True') {
                dispatch(teamReportExists({ usertoken, team_id })).then(() => {
                    setCnfDelete(false)
                }
                )
            }
        })
    }
    useEffect(() => {
        console.log('initialCheck','IssueSurvey')
        if (team_id != '') {
            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                console.log("res from team mem server", teamMemList)
            })
        }
    }, [team_id])
    useEffect(() => {
        dispatch(teamReportExists({ usertoken, team_id })).then((res) => {
            if (res.payload.Result.Exists == "True") {
                dispatch(PublishTeamReport({ usertoken, team_id }))
            }
        })
    }, [team_id])

    useEffect(() => {
        if (senderInformation.length != 0) {
            senderInformation.map((x, i) => {
                console.log("sender information total",x)
                let teamId = recipient[i].teamId
                let personId = recipient[i].personId
                let email = recipient[i].emailAddress
                const data = {
                    link: x.survey_link,
                    teamId: teamId,
                    personId: personId
                };
                emailjs.send('service_kooyngm', 'template_6vkqbul', x, 'Ha6CtAyRG7PFMtJOQ')
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                        dispatch(recordSurveyMail({ usertoken, teamId, personId, email })).then((res) => {
                            dispatch(teamsMemServer({ usertoken, team_id })).then((res) => {
                                dispatch(setMailSubject(''))
                                dispatch(setMailBody(''))
                                console.log('checkData', data)
                            })
                        })
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
            })
            recipient.map((x, index) => {
                dispatch(removeRecipient(index))
            })
            dispatch(setSenderInformationEmpty([]))
            document.getElementsByClassName('PrivateSwitchBase-input')[0].click()
            setIsOpenTeamToggle(false)
            setIsOpenInfoIcon(true)
        }
    }, [senderInformation])

    useEffect(() => {
        if (teamsList.length != 0 && teamsList[0].Team_Id) {
            console.log('useEffect',location)
            if(location.state.Team_Id==''){
            dispatch(setTeamMemId(teamsList[0].Team_Id))
        }else{
            console.log('elseCase')
            dispatch(setTeamMemId(location.state.Team_Id))
        }
        }
    }, [teamsList]);

    const handlCloseiconview = (value) => {
        if (value == "closeicon") {
            setIsOpenTeamToggle(false);
            setIsOpenInfoIcon(true);
        }
        else if (value == "infoicon") {
            setIsOpenTeamToggle(true);
            setIsOpenInfoIcon(false);
        }

    }
    const languagelist = [
        {
            "Team_Id": "01",
            "Team_Name": "English",
            "Active": "Y"
        },
        {
            "Team_Id": "02",
            "Team_Name": "Portuguese",
            "Active": "Y"
        }
    ]
    const styleList = [
        {
            "Team_Id": "01",
            "Team_Name": "",
            "Active": "Y"
        },
        {
            "Team_Id": "02",
            "Team_Name": "",
            "Active": "Y"
        }
    ]
    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'emailAddress',
            headerName: 'Email',
            flex: 1,
            editable: false,
        },
        {
            field: 'dateSent',
            headerName: 'Date Sent',
            flex: 1,
            editable: false,
        },
        {
            field: 'dateSubmitted',
            headerName: 'Date Submitted',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                return(
                    <div style={{ flex: "auto" }}>
                        {params['row']['dateSubmitted'] === '' ? <a href = {params['row']['questionnaireLink']} target = '_blank'>Survey Link</a> : <p>{params['row']['dateSubmitted']}</p>}
                    </div>
                )
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            editable: false,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div style={{ flex: "auto" }}>
                        <BtnContent teamStatus={true} color={params.row.dateSubmitted != '' ? 'success' : 'warning'}>{params.row.dateSubmitted != '' ? 'completed' : 'pending'}</BtnContent>
                    </div>
                )
            }
        },
    ];
    
    const rows = [
        { id: 1, Name: 'Team A', DateSent: '09/04/2022 ', DateReceived: '09/04/2022 ', DatePublished: '09/04/2022 ', Action: "pdf" },
        { id: 2, Name: 'Team B', DateSent: '9/10', DateReceived: '28/04/2022', DatePublished: '', Action: "sent" },
        { id: 3, Name: 'Team C', DateSent: '8/10', DateReceived: '24/04/2022', DatePublished: '', Action: "sent" },


    ];
    const columnsPublished = [
        { field: 'id', headerName: 'ID', flex: 1, },
        {
            field: 'Style',
            headerName: 'Style',
            flex: 1,
            editable: true,
        },
        {
            field: 'Datepublished ',
            headerName: 'Date Published ',
            flex: 1,
            editable: true,
        },
        {
            field: 'Attachment',
            headerName: 'Attachment',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>
                        <span id={"checkboxpublished" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}><input type="checkbox"></input>
                        </span>
                    </div>
                )
            }
        },

        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>

                        <span id={"sent" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}>
                            <SendIcon />
                        </span>
                        <span id={"pdf" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => setCnfDelete(true)}>
                            <DeleteOutlineIcon />
                        </span>

                    </div>
                )
            }
        }

    ];
    const rowspublished = [
        { id: 1, Style: 'Team Workology', Datepublished: '09/04/2022 ', Attachment: 'checkboxpublished', DatePublished: '09/04/2022 ', Action: "pdf" },
    ];
    const columnsTeamList = [
        { field: 'id', headerName: 'ID', flex: 1, },
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
            editable: true,
        },
        {
            field: 'DateSent',
            headerName: 'Date Sent',
            flex: 1,
            editable: true,
        },
        {
            field: 'DateReceived',
            headerName: 'Date Received',
            flex: 1,
            editable: true,
        },
        {
            field: 'DatePublished',
            headerName: 'Date Published',
            flex: 1,
            editable: true,
        },
        {
            field: 'Status',
            headerName: 'Status',
            flex: 1,
            editable: true,
        },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>

                        <span id={"sent" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}>
                            <SendIcon />
                        </span>
                        <span id={"pdf" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("share")}>
                            <PictureAsPdfIcon />
                        </span>

                    </div>
                )
            }
        }

    ];
    const rowsTeamList = [
        { id: 1, Name: 'Alex Pan', DateSent: '09/04/2022 ', DateReceived: '09/04/2022 ', DatePublished: '09/04/2022 ', Status: "Complete", Action: "pdf" },
        { id: 2, Name: 'James Pan', DateSent: '09/04/2022 ', DateReceived: '', DatePublished: '', Status: "", Action: "" },
        { id: 3, Name: 'Dhinesh Devarajan', DateSent: '09/04/2022 ', DateReceived: '', DatePublished: '', Status: "", Action: "" },


    ];
    const columnsPublishedTeamList = [
        { field: 'id', headerName: 'ID', flex: 1, },
        {
            field: 'Name',
            headerName: 'Name',
            flex: 1,
            editable: true,
        },
        {
            field: 'Datepublished ',
            headerName: 'Date Published ',
            flex: 1,
            editable: true,
        },
        {
            field: 'Attachment',
            headerName: 'Attachment',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>
                        <span id={"checkboxpublished" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}><input type="checkbox"></input>
                        </span>
                    </div>
                )
            }
        },

        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            editable: true,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <div>

                        <span id={"sent" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}>
                            <SendIcon />
                        </span>
                        <span id={"pdf" + params.row.id} className="pd-r-15px cursor-pointer" onClick={() => window.alert("share")}>
                            <DeleteOutlineIcon />
                        </span>

                    </div>
                )
            }
        }

    ];
    const rowspublishedTeamList = [
        { id: 1, Name: 'Alex Pan', Datepublished: '09/04/2022 ', Attachment: 'checkboxpublished', DatePublished: '09/04/2022 ', Action: "pdf" },
    ];
    const addEditTeam = () => {
        window.alert("add/edit team")
    }
    const removeTeam = () => {
        window.alert("remove team")
    }

    const rowClick = (params, event) => {
        window.alert('test')
    }
    const checkBoxSelected = (id) => {
        // window.alert(id)
        setCheckBoxTracker(checkBoxTracker => id)
        dispatch(addRecipient(teamMemList.filter(o1 => id.some(o2 => o2 === o1.id))))
        if (id.length != 0) {
            setIsOpenTeamToggle(true)
            setIsOpenInfoIcon(false)
        }
        else {
            // setIsOpenTeamToggle(false)
            // setIsOpenInfoIcon(true)
        }
    }
    const [age, setAge] = React.useState(10);

    const handleChange = (event) => {
        console.log('event', event.target.value, allTemplate)
        const findTemp = allTemplate.find(x => x.templateId == event.target.value)
        setAge(event.target.value)
        dispatch(setMailSubject(findTemp.subject))
        dispatch(setMailBody(findTemp.subjectMessage))
        // console.log('eventslc',event)
        // alert(event)
    };
    function publishFunction() {
        var personId = teamMemList[0].personId
        dispatch(PublishTeamReport({ usertoken, team_id })).then(() => {
            dispatch(teamReportExists({ usertoken, team_id })).then(() => {
                setPublishLoader(false)
            })
        })
    }
    
    const outputReturn = (data) => {
        let datas = data.split('/n')
        return datas.join('\n')
    }


    return (
        <Container maxWidth="xl" style={{ paddingBottom: "20px"}}>

            <ConfirmDelete open={cnfDelete} title={'Confirm Delete'} btnNameOne={'Yes'} clickBtnNameOne={() => reportDelete()} clickBtnNameTwo={() => setCnfDelete(false)} btnNameTwo={'No'} close={() => setCnfDelete(false)} />
            {console.log('snackbarcheck', snackmessageCondition, snackmessage, type)}
            <SnackBar condition={snackmessageCondition} message={snackmessage} type={type} />
            {isMessageLoading && <BackDropLoader loaderBool={isMessageLoading} />}
            {/* <Container maxWidth="xl"> */}
            <SnackBar condition={messageIssueCondition} message={messageIssue} type={issueType} />
            <Grid
                justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                alignItems="center"
                rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                className="text-center">
                <Stack
                    direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                    spacing={2}
                    justifyContent={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' }}
                    alignItems="start" mb={1}>
                    <BreadCrumb breadCrumbName={"Survey & Report"} />
                </Stack>
            </Grid>
            <Divider />
            {showPublishBtn > 1 &&
                <div className="publishbtn">

                    {showPublishBtn > 1 &&

                        <Grid style={{ marginTop: 0 }} item xs={8} sm={8} md={8} lg={12} xl={12} sx={{ display: "flex", alignItems: 'center' }}>

                        </Grid>}
                </div>}
            {console.log('threecase',)}
            <Grid
                style = {{marginTop : '0px' }}
                container
                justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'space-between' }}
                alignItems="start"
                rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                className="mb-10">
                <Grid className="openvaluesurvey p-l12" item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : '7'} xl={isOpenInfoIcon == true ? 'col' : '7'}>
                    <Grid direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems="center" className="mb-20 position-absolute">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <Grid item xs={12} sm={12} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h1 className="header_title m-b-0 text-capitalize" >
                                        {/* Team Survey and Result */}
                                        survey & report    
                                    </h1>
                                </Grid>
                                <Grid style={{ marginTop: 20 }} item xs={12} sm={12} md={12} lg={12} sx={{ display: "flex", alignItems: 'center' }}>
                                    <Grid item xs={12} sm={12} md={"col"} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'} sx={{ display: "flex", alignItems: 'center' }} >
                                        <text>Select a team</text>
                                        <div className='pd-l-10px' style={{ width: "260px" }}>
                                            {console.log('teamList', teamsList)}
                                            <CommonSelect list={teamsList} label={teamsList.length != 0 &&location.state.Team_Id ==''? teamsList[0].Team_Id.toString():location.state.Team_Id.toString()} />
                                        </div>

                                    </Grid>

                                    {showPublishBtn > 1 && publishTableShow.hasOwnProperty('Result') && publishTableShow.Result.Exists == 'False' &&
                                        <Grid item xs={12} sm={12} md={"col"} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'} display={{ xs: "none", sm: "none", md: "block", lg: "block" }} sx={{ display: "flex", justifyContent: "flex-end" }} className=" publishbtnTop">
                                            <div className="">
                                                <BtnContent className="" click={() => {
                                                    setPublishLoader(true)
                                                    publishFunction()
                                                }} color={"primary"}><span>{publishLoader ? <CircularProgress size={20} color="inherit" /> : 'publish report'}</span></BtnContent>
                                            </div>
                                        </Grid>
                                    }

                                </Grid>
                                {showPublishBtn > 1 && publishTableShow.hasOwnProperty('Result') && publishTableShow.Result.Exists == 'False' && <Grid style={{ marginTop: 20 }} item xs={12} sm={12} md={12} lg={12} display={{ xs: "block", sm: "block", md: "none", lg: "none" }} sx={{ display: "flex", alignItems: 'center' }} className="publishbtnmv">
                                    <div className="">
                                        <BtnContent className="" click={() => {
                                            setPublishLoader(true)
                                            publishFunction()
                                        }} color={"primary"}><span>{publishLoader ? <CircularProgress size={20} color="inherit" /> : 'Publish'}</span></BtnContent>
                                    </div>
                                </Grid>}

                            </Grid>
                            {publishTableShow.hasOwnProperty('Result') && publishTableShow.Result.Exists == 'True' && <Grid item mt={2} xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'} display={{ xs: "none", sm: "none", md: "block", lg: "block", xl: 'block' }}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <h1 className="header_title pub m-b-0" >Published Reports</h1>
                                </Grid>
                                <Grid style={{ marginTop: 15 }} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", alignItems: 'center' }}>
                                    <Grid className="" item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'}>
                                        <Grid direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems="center" className="">
                                            <TableContainer >
                                                <Table sx={{ minWidth: "auto" }} aria-label="simple table" className="publishtable">
                                                    <TableHead>
                                                        <TableRow>

                                                            <TableCell align="start" width={"25px"}></TableCell>
                                                            <TableCell align="start">Name</TableCell>
                                                            <TableCell align="start" width={"100px"}>Action</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                            <TableCell align="start"><input type="checkbox" checked={attachment} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    // setIsOpenTeamToggle(true)
                                                                    // setIsOpenInfoIcon(false)
                                                                    dispatch(setMailPdfLink(pdfLink))
                                                                    setAttachment(true)
                                                                    setIsOpenTeamToggle(true)
                                                                    setIsOpenInfoIcon(false)
                                                                }
                                                                else {
                                                                    dispatch(setMailPdfLink(''))
                                                                    // setIsOpenTeamToggle(false)
                                                                    // setIsOpenInfoIcon(true)
                                                                    setAttachment(false)
                                                                }
                                                            }}></input></TableCell>
                                                            <TableCell style={{ textDecorationLine: 'underline', color: '#1976d2' }} align="start"><label><a href={pdfLink} target='_blank'>{pdfName}</a></label></TableCell>
                                                            <TableCell align="start">
                                                                <div>

                                                                    {/* <span id={"sent"} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}>
                                                                        <SendIcon />
                                                                    </span> */}
                                                                    <span id={"pdf"} className="pd-r-15px cursor-pointer" onClick={() => setCnfDelete(true)}>
                                                                        <DeleteOutlineIcon />
                                                                    </span>

                                                                </div>
                                                            </TableCell>

                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>}


                        </Grid>




                        {/* <Grid item xs={12} sm={12}  md={6} lg={6} xl={6}   sx={{ display: "flex", justifyContent: "flex-end" }}  className="pt-30  publishbtnTop">
                         <BtnContent className="" click={() => publishFunction()} color={"primary"}><span>Publish</span></BtnContent>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* {
                            teamMemList.map(x=>{
                                teamsQueLink.map(y=>{
                                    if(x.personId==y.personId){
                                        x.queLink=y.link
                                    }
                                })
                            })
                        } */}
                        {console.log('mergedQUELINK', teamMemList)}

                        <CommonDataGrid columns={columns} rows={teamMemList} checkBoxSelected={checkBoxSelected} normalgridheight={true} />
                    </Grid>
                </Grid>

                {publishTableShow.hasOwnProperty('Result') && publishTableShow.Result.Exists == 'True' && <Grid item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : '3'} xl={isOpenInfoIcon == true ? 'col' : '3'} display={{ xs: "block", sm: "block", md: "none", lg: "none", xl: 'none' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h1 className="header_title m-b-0" >Published Reports</h1>
                    </Grid>
                    <Grid style={{ marginTop: 20 }} item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", alignItems: 'center' }}>
                        <Grid className="" item xs={12} sm={12} md={12} lg={isOpenInfoIcon == true ? 'col' : 'col'} xl={isOpenInfoIcon == true ? 'col' : 'col'}>
                            <Grid direction={{ xs: 'row', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} container xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="space-between" alignItems="center" className="">
                                <TableContainer >
                                    <Table sx={{ minWidth: "auto" }} aria-label="simple table" className="publishtable">
                                        <TableHead>
                                            <TableRow>

                                                <TableCell align="start" width={"25px"}></TableCell>
                                                <TableCell align="start">Name</TableCell>
                                                <TableCell align="start" width={"100px"}>Action</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                                                <TableCell align="start"><input type="checkbox" checked={attachment} onChange={(e) => {
                                                    if (e.target.checked) {
                                                        dispatch(setMailPdfLink(pdfLink))
                                                        // setIsOpenTeamToggle(true)
                                                        // setIsOpenInfoIcon(false)
                                                        setAttachment(true)
                                                        setIsOpenTeamToggle(true)
                                                        setIsOpenInfoIcon(false)
                                                    }
                                                    else {
                                                        dispatch(setMailPdfLink(''))
                                                        // setIsOpenTeamToggle(false);
                                                        // setIsOpenInfoIcon(true);
                                                        setAttachment(false)
                                                    }
                                                }}></input></TableCell>
                                                <TableCell style={{ textDecorationLine: 'underline', color: '#1976d2' }} align="start">
                                                    <label><a href={pdfLink} target='_blank'>{pdfName}</a></label></TableCell>
                                                <TableCell align="start">
                                                    <div>

                                                        {/* <span id={"sent"} className="pd-r-15px cursor-pointer" onClick={() => window.alert("edit")}>
                                                                        <SendIcon />
                                                                    </span> */}
                                                        <span id={"pdf"} className="pd-r-15px cursor-pointer" onClick={() => setCnfDelete(true)}>
                                                            <DeleteOutlineIcon />
                                                        </span>

                                                    </div>
                                                </TableCell>

                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>}

                <Grid item className="surveytoggle">
                    <span>{isOpenTeamToggle == true && <IconBtn toggle={true} click={() => handlCloseiconview('closeicon')} icon={<ArrowForwardIosIcon />} />}</span>
                    <span >{isOpenInfoIcon == true && <IconBtn toggle={true} click={() => handlCloseiconview('infoicon')} icon={<ArrowBackIosIcon className="backwardicon" />} />}</span>
                </Grid>

                {console.log('allTemplate', allTemplate, mailBody, mailSubject, age)}
                {console.log('isOpenTeamToggle',isOpenTeamToggle)}
                {isOpenTeamToggle && <Grid className="" item xs={12} sm={12} md={12} lg={4} xl={4} mt={{ xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }}>

                    <form className="mt-7r">
                        <Grid container rowSpacing={3}>
                            <Grid sx={{ display: "flex" }} item xs={12} sm={12} md={12} lg={12} xl={12} className="p-10">
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                        <label for="id" className="text-capitalize ">email template</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Select
                                            size="small"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            //label="Template"
                                            fullWidth
                                            onChange={handleChange}
                                        >
                                            {allTemplate.map((x) => <MenuItem value={x.templateId} selected>{x.templateId}</MenuItem>)}


                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                        <label for="id" className="text-capitalize">Recipient</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box style={{ maxheight: "103px", overflowX: "auto" }} padding={1}
                                            sx={{
                                                width: 'auto',
                                                height: recipient.length > 0 ? 'auto' : 20,
                                                border: '1px solid grey',

                                            }}
                                        >
                                            {console.log('checkRecipient',recipient)}
                                            {recipient.map((x, index) =>
                                                <div>
                                                    {console.log('chip', x)}
                                                    <Chip
                                                        label={x.name}
                                                        variant="outlined"
                                                        onDelete={() => {
                                                            dispatch(removeRecipient(index))
                                                        }}
                                                    />
                                                    <div style={{ height: 5 }}></div>
                                                </div>

                                            )}

                                        </Box>
                                        {attachment && !sendNonMail && <a onClick={() => setSendNonMail(!sendNonMail)} className=" cursor-pointer">Add non-Team Member email</a>}
                                        {sendNonMail && <ChipInput
                                            variant="outlined"
                                            style = {{marginTop : '11px'}}
                                            className='ipt-size'
                                            defaultValue={unknowMailArray}
                                            onChange={(chips) => {
                                                setUnknownMailArray(unknowMailArray => chips)
                                            }}
                                        />}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                        <label for="id" className="text-capitalize">BCC</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <ChipInput
                                            variant="outlined"
                                            className='ipt-size scrl'
                                            defaultValue={bcc}
                                            onChange={(chips) => {
                                                setBcc(bcc => chips)
                                                dispatch(setBccMail(chips))
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {attachment &&
                                <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container alignItems={"center"}>
                                        <Grid item xs={12} sm={12} md={12} lg={5} xl={5} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                            <label for="name" className="text-capitalize">Attachment</label>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={9} xl={7}>
                                            <span className="cut-text"><a href={mailPdfLink} target="_blank">{pdfName}</a></span>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                        <label for="name" className="text-capitalize">subject</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField size="small" className='ipt-size' value={mailSubject} onChange={(e) => dispatch(setMailSubject(e.target.value))} autoComplete="off" id="name" variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sx={{ display: "flex", alignItems: "center" }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container alignItems={"center"}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={{ xs: "start", sm: "start", md: "start", lg: "start", xl: "start" }}>
                                        <label for="description" className="text-capitalize">message</label>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextareaAutosize
                                            className='ipt-size'
                                            aria-label="minimum height"
                                            id="description"
                                            value={outputReturn(mailBody)}
                                            onChange={(e) => {
                                                let mailMess = e.target.value.split('\n')
                                                dispatch(setMailBody(mailMess.join('/n')))
                                            }}
                                            minRows={5}
                                            autoComplete="off"
                                            placeholder=""
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            mt={1}
                            container
                            justifyContent={"flex-end"}
                            alignItems="center"
                            rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                            className="text-center">
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: "flex", justifyContent: "start" }}>
                                <div className='mar-r-10'>
                                    <BtnContent color={"primary"} click={() => {
                                        if (unknowMailArray.length != 0) {
                                            let data = mailBody.split('/n')
                                            let newData = data.join('<br/>')
                                            unknowMailArray.map((x,index) => {
                                                var x = {
                                                    from_name: "hieu.phan@clarity4d.com",
                                                    to_name: x,
                                                    email_id: index,
                                                    mail_subject: mailSubject,
                                                    bcc : bcc.toString(),
                                                    message: newData,
                                                    // survey_link : 'http://localhost:3000/questionnaire/?usertoken=PUBLIC&teamId='+x.teamId+'&personId='+x.personId+'&firstName='+x.forename+'&surName='+x.surname+'&email='+x.emailAddress
                                                    // survey_link: (state.mailPdfLink == '' ? 'http://localhost:3000/surveyForm/?usertoken=PUBLIC&teamId=' + x.teamId + '&personId=' + x.personId + '&key1=' + key1 + '&key2=' + key2 + '&key3=' + key3 : state.mailPdfLink)
                                                    survey_link: mailPdfLink
                                                 }
                                                emailjs.send('service_kooyngm', 'template_6vkqbul', x, 'Ha6CtAyRG7PFMtJOQ')
                                                .then(function (response) {
                                                    console.log('SUCCESS!', response.status, response.text);
                                                    setUnknownMailArray([])
                                                    dispatch(setMailPdfLink(''))
                                                }, function (error) {
                                                    console.log('FAILED...', error);
                                                });  
                                            })
                                        }
                                        
                                        dispatch(sendMail(usertoken))
                                        dispatch(setMailPdfLink(''))
                                        setUnknownMailArray([])
                                        setBcc([])
                                        setAttachment(false)
                                        setSendNonMail(false)
                                        dispatch(setBccMail([]))
                                    }}>
                                        <span>send</span>
                                    </BtnContent>
                                </div>
                                <div>
                                    <BtnContent color={"primary"} click={() => {
                                        console.log("checkboxTracker", checkBoxTracker)
                                        setUnknownMailArray([])
                                        setBcc([])
                                        dispatch(setMailSubject(''))
                                        dispatch(setMailBody(''))
                                        setSendNonMail(false)
                                        if (checkBoxTracker.length != 0) {

                                            document.getElementsByClassName('PrivateSwitchBase-input')[0].click()
                                            setIsOpenTeamToggle(true)
                                            setIsOpenInfoIcon(false)
                                        }
                                        recipient.map((x, index) => {
                                            dispatch(removeRecipient(index))
                                        })
                                        // if(recipient.length() != 0 ){

                                        // }
                                        dispatch(setMailPdfLink(''))
                                        setUnknownMailArray([])
                                        setBcc([])
                                        dispatch(setBccMail([]))
                                        setAttachment(false)
                                    }}>
                                        <span>cancel</span>
                                    </BtnContent>
                                </div>
                            </Grid>
                        </Grid>
                    </form>

                </Grid>}
            </Grid>

            {console.log('checkexists', publishTableShow.hasOwnProperty('Result') && publishTableShow.Result.Exists == 'True')}


            <Grid
                container
                justifyContent={{ xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' }}
                alignItems="center"
                rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 3 }}
                className="mb-10"
                display={{ xs: "none", sm: "none", md: "inline-flex", lg: "inline-flex" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconContentCard cursor = {false} clrwhite={"#fff"} bgColor={"#1bc5bd"} icon={<GroupsIcon className="message-icn-size" />} cardTitle={"Recipients"} description={"Only members of the team can be added / selected as recipients of the survey. Each recipient will receive a unique link that person only. Once submitted can not be resubmitted"} />

                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IconContentCard cursor = {false} clrwhite={"#fff"} bgColor={"#ea6060"} icon={<BarChartIcon className="message-icn-size" />} panelcolor={true} cardTitle={"Survey"} description={"Provide a unique link for individual to complete the 48-question survey. Once an individual has submitted their response to the 48 questions <br> Questionnaire"} />

                    </Grid>
                </Grid>
            </Grid>
            <ReportDialog open={reportOpen} title={"Report"} btnNameOne={"Generate Report"} clickBtnNameOne={() => {
                // window.alert("triggered")
                let doc = new jsPDF('p', 'pt', 'a4', true);
                doc.html(document.getElementById('pdf-view'), {
                    callback: () => {
                        doc.save('test.pdf');
                    }
                });
            }} clickBtnNameTwo={() => setReportOpen(!reportOpen)} btnNameTwo={"cancel"} close={() => setReportOpen(!reportOpen)} />
        </Container>



    )

}

export default IssueSurvey
