import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import '../common-header/header.css';
import { useNavigate } from 'react-router';
import teamlogo from '../../assets/teamlogo.jpg';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header(props) {
  const navigate = useNavigate()
  const {notificationCount} = useSelector((store) => store.login)
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.clear()
    navigate('/',{replace : false})
    window.location.reload()
  }
  return (
    <div className="bg-header">
   <AppBar position="fixed" open={props.open} className="headermb">
       {/* <AppBar className = "back-color" position="fixed" open={props.open}> */}
        <Toolbar>
          {props.showMenu && <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.draweOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(props.open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>}
          <Typography variant="" noWrap component="div" sx={{ flexGrow: 1 }}>
          <div>
        <span><img src={teamlogo} alt="logo" width='143px'/></span> 
        </div>
          </Typography>
          <Box sx={{display: { xs: 'flex', md: 'none' } }}>
          {props.showMenu && <IconButton
              className="bg-gray"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick = {props.notificationTrigger}
              >
                <Badge className="bg-gray" onClick = {props.notificationTrigger} badgeContent={notificationCount} color="secondary">
                {props.notificationIcon}
                </Badge>
              </IconButton>}
            <IconButton
              size="large"
              aria-label="account of current user"
              color="inherit"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <AccountCircle />
            </IconButton>
            {/* <Menu
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem>
                  <Typography textAlign="center">{props.userInformation}</Typography>
                </MenuItem>
                <MenuItem onClick = {() => {
                navigate('/preference',{replace : false})
                handleClose()
                }}>
                  <Typography textAlign="center">{props.userInformation}</Typography>
                </MenuItem>
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Menu> */}
          </Box>
          <Box sx={{display: { xs: 'none', md: 'flex' } }}>
              <div className='line-height-normal bg-gray'>
                {props.userInformation}
              </div>
              {/* <IconButton
              className="bg-gray"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick = {props.notificationTrigger}
              >
                {props.notificationIcon}
              </IconButton> */}
              {props.showMenu && <IconButton
              className="bg-gray"
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick = {props.notificationTrigger}
              >
                <Badge className="bg-gray" onClick = {props.notificationTrigger} badgeContent={notificationCount} color="secondary">
                {props.notificationIcon}
                </Badge>
              </IconButton>}
              <IconButton
              className="bg-gray"
                size="large"
                color="inherit"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                {props.personIcon}
              </IconButton>
              <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick = {() => {
                navigate('/preference',{replace : false})
                handleClose()
                }}>Profile</MenuItem>
                <a href = "https://academy.peopleperform.co.uk/ourteamsynergylearning" target='_blank' style = {{color : 'inherit'}}><MenuItem>Feedback</MenuItem></a>
              {/* <MenuItem>My account</MenuItem> */}
              <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Menu>
              {/* <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                className="bg-gray"
              >
                {props.vertIcon}
              </IconButton> */}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
   
  );
}
